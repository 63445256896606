import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './App.css';
import ArcGISMap from './ArcGISMap';

function App() {
  const defaultShowSplash = Cookies.get('showSplash') === 'false' ? false : true;
  
  // Define a state to control the display of the splash screen
  const [showSplash, setShowSplash] = useState(defaultShowSplash);
  const [showMapKey, setShowMapKey] = useState(true); 
  const [hasAcceptedTerms, setAcceptedTerms] = useState(false);

  // Set cookies when states change
  useEffect(() => {
    Cookies.set('showSplash', showSplash);
  }, [showSplash]);


  return (
    <div className="App h-screen flex flex-col">
      
      {showSplash ? (
        <div className="SplashScreen bg-white p-10 flex flex-col pl-4 pr-4 md:pl-32 md:pr-32 lg:pl-72 lg:pr-72">
          <h1 className="font-bold text-3xl mb-6 text-center">EastBay Hazard Insight Map</h1>
          <p className="font-bold mb-6">
            Navigating East Bay's Natural Hazards
          </p>
          <p className="mb-2">
            For those considering a new home in the East Bay near San Francisco, it's helpful to understand the natural hazards that may affect a property.
          </p>
          <p className="mb-2">
            This tool leverages ArcGIS data from public government sources to inform and equip potential homeowners to make an informed home buying decision in the East Bay.
          </p>
          <p className="font-bold mb-6 mt-12">
            What is EastBay Hazard Insight? 
          </p>
          <p className="mb-6">EastBay Hazard Insight is a map designed to provide clarity on potential hazards in the region:</p> 
          <div className="mb-6">
            <ul className="list-inside list-disc">
              <li>Easily search by property address.</li>
              <li>Assess seismic risks such as liquifaction zones and landslide history.</li>
              <li>Identify potential flooding zones in the vicinity.</li>
              <li>Understand wildfire risk.</li>
            </ul>
          </div>
          <p className="mb-2">Terms/Conditions: This map is provided as a guide only. Always consult with local experts and authorities before making a purchase decision.</p>
          <div className="flex items-center mb-4">
            <input 
              type="checkbox" 
              id="termsCheckbox" 
              className="mr-2"
              checked={hasAcceptedTerms} 
              onChange={(e) => setAcceptedTerms(e.target.checked)}
            />
            <label htmlFor="termsCheckbox">I accept the terms and conditions.</label>
          </div>
          <div className="w-full flex justify-center mt-4">
          <button 
            className={`bg-blue-500 text-white px-4 py-2 w-64 rounded ${hasAcceptedTerms ? 'hover:bg-blue-600 cursor-pointer' : 'opacity-50 cursor-not-allowed'}`} 
            onClick={() => hasAcceptedTerms && setShowSplash(false)}
          >
            View Hazard Map
          </button>
          </div>
        </div>
        
      ) : (
        <div className="Map w-full h-full flex">
          <ArcGISMap />
          {showMapKey ? (
            <div className="absolute top-0 right-0 bg-white p-4 mt-24 mr-4 w-80 h-5/6 border-l border-gray-300 overflow-y-auto">
              <h2 className="text-lg mb-4">Map Information</h2>
              {/* Add your map key content here */}
              <button 
                onClick={() => setShowMapKey(false)}
                className="absolute top-2 right-2 bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
              >
                -
              </button>
              <p className="mb-2 text-sm">Source: <a href="https://maps.conservation.ca.gov/cgs/" target="_blank" className="text-blue-500">California Geological Survey</a></p>
              <h3 className="font-bold mb-2 mt-6">Notes</h3>
              <div className="notes text-sm">
                <ul className="list-inside list-disc">
                  <li className="mb-2">Some map data terminates north of Berkeley. For example, historic landslides <a className="text-blue-500" href="https://www.el-cerrito.org/DocumentCenter/View/463/Hazard-Map-1" target="_blank">continue into El Cerrito</a> but are missing from GIS.</li>  
                  <li className="mb-2">The Earthquake Hazard Parcels layer will appear once the map is zoomed into an address</li>
                </ul>
              </div>
              <h3 className="font-bold mb-2 mt-6">Instructions</h3>
              <div className="instructions text-sm">
                <p className="mb-2"><img className="float-right w-10" src="/keys/legend-menu.png" alt="legend menu"/>Click the legend menu to show and hide layers: </p>
                
                <p className="mb-2">Then click the dropdown to view the legend for a layer:</p>
                <img src="/keys/legend-dropdown.png" alt="legend dropdown" />
              </div>
              <h3 className="font-bold mb-2 mt-6">Legend Glossary</h3>
              <div className="glossary text-sm">
                <p className="mb-2"><b>Tsunami Hazard Line:</b> Represents maximum considered tsunamis for the area.</p>
                <p className="mb-2"><b>Fault Zone:</b> Regulatory zone boundaries delineate areas where surface fault rupture previously has occurred, or where local topographic, geological, and geotechnical conditions indicate a potential for permanent ground displacements</p>
                <p className="mb-2"><b>Fault Traces:</b> Underlying located fault lines.</p>
                <p className="mb-2"><b>Liquefaction Zones:</b> Areas where liquefaction and landslides may occur during a strong earthquake.</p>
                <p className="mb-2"><b>FHSZ in LRA/SRA:</b> Fire hazard severity zones. These can either be local (LRA) or state (SRA) responsibility.</p>
                <p className="mb-2"><b>Landslide Older:</b> Includes landslides categorized as Dormant Mature, Dormant Old/Relict, or Dormant Age Not Specified. <a className="text-blue-500" href="https://www.conservation.ca.gov/cgs/PublishingImages/Landslides/Activity_graphic.jpg" target="_blank">Definitions</a></p>
                <p className="mb-2"><b>Landslide Younger:</b> Includes landslides categorized as Active/Historic and Dormant Young. <a className="text-blue-500" href="https://www.conservation.ca.gov/cgs/PublishingImages/Landslides/Activity_graphic.jpg" target="_blank">Definitions</a></p>
                <p className="mb-2">
                  <b>Earthquake Hazard Parcels:</b><br/>(Zoom in to address level to view.)
                  <img src="/keys/parcels.png" alt="earthquake hazard parcels" className=""/>
                </p>
              </div>
            </div>
          ) : (
            <div className="absolute z-10 absolute bottom-8 right-4">
              <button 
                className="z-10 bg-blue-500 text-white text-sm px-3 py-1 rounded hover:bg-blue-600"
                onClick={(event) => {
                  setShowMapKey(true);
                }}
              >
                menu
              </button>
            </div>
          )}
        </div>
      )}

    </div>
  );
}

export default App;
