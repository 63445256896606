// src/ArcGISMap.js

import React from 'react';

const ArcGISMap = () => {
    return (
        <div className="map-container h-full w-full">
            <style>
                {`
                .embed-container {
                    position: relative; 
                    height: 100%; 
                    max-width: 100%;
                    overflow: hidden;
                }
                .embed-container iframe, 
                .embed-container object, 
                .embed-container iframe{
                    position: absolute; 
                    top: 0; 
                    left: 0; 
                    width: 100%; 
                    height: 100%;
                }
                
                `}
            </style>
            <div className="embed-container absolute inset-0">
                <iframe 
                    className="w-full h-full z-0" 
                    frameBorder="0" 
                    scrolling="no" 
                    title="Bay Area Hazard Maps" 
                    src="//www.arcgis.com/apps/Embed/index.html?webmap=7b12a0c30d1749528fa8eedd3739cc3a&extent=-122.3486,37.7967,-122.1299,37.8978&zoom=true&previewImage=false&scale=true&search=true&searchextent=true&legendlayers=true&disable_scroll=true&theme=light">
                </iframe>
            </div>
        </div>
    );
}

export default ArcGISMap;
